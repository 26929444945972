import type { FC } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { UserDataObj } from "src/types/snugtotal";

interface TopReadonlyProps {
  onMobileNavOpen?: () => void;
  userData: UserDataObj | null;
}

export const TopReadonly: FC<TopReadonlyProps> = (props) => {
  const { userData } = props;

  return (
    <Box
      component="header"
      sx={{
        position: "fixed",

        top: 0,
        width: {
          md: `100%`,
        },
        zIndex: 9995,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#418f56",
          py: 0.7,
          color: "primary.contrastText",
          height: "48px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container maxWidth={"lg"} sx={{ height: "100%" }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems={"center"}
            textAlign={"left"}
            justifyContent={"center"}
            sx={{ height: "100%" }}
          >
            <Typography variant="button" sx={{ textTransform: "none" }}>
              {`You are viewing ${userData?.full_name || ""}'s plan with read-only access.`}
            </Typography>

            <Button
              endIcon={<EastOutlinedIcon />}
              variant="text"
              color="inherit"
              sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}
            />
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

TopReadonly.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
