import { type FC } from "react";
import PropTypes from "prop-types";
import type { SxProps } from "@mui/system/styleFunctionSx";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import CheckCircleSolidIcon from "src/icons/untitled-ui/duocolor/check-circle-solid";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ReplyIcon from "@mui/icons-material/Reply";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import useUserDataProvider from "src/contexts/userData-context";
import { TargetPeopleRole } from "src/types/snugtotal";
import { authorizationRolesArray } from "src/utils/snug";
import { CircularProgress } from "@mui/material";
import DotsVertical from "@untitled-ui/icons-react/build/esm/DotsVertical";
import toast from "react-hot-toast";

interface TenantSwitchProps {
  sx?: SxProps;
  onAddPlanClick: () => void;
  onVerifyUserClick: (id: string) => void;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export const TenantSwitch: FC<TenantSwitchProps> = (props) => {
  const {
    ownerUserData,
    targetAuthRoles,
    loadingViewingUserData,
    userData,
    proClientContext,
    targetAuthRolesLoading,
    clientRole,
    handleSetAuthorizationContext,
  } = useUserDataProvider();

  const router = useRouter();
  let displayList: TargetPeopleRole[] = [];

  if (proClientContext) {
    displayList = [
      {
        role: "SNUG_OWNER",
        id: "",
        user_data: proClientContext,
        user_data_ud_id: proClientContext?.ud_id,
      },
      ...targetAuthRoles.filter((pR) =>
        authorizationRolesArray.includes(pR.role || "")
      ),
    ];

    if (proClientContext.spouse) {
      displayList.splice(1, 0, {
        role: "SPOUSE",
        id: "",
        user_data: proClientContext.spouse,
        user_data_ud_id: proClientContext?.spouse?.ud_id,
      });
      //remove
    }
  } else {
    displayList = [
      {
        role: "SNUG_OWNER",
        id: "",
        user_data: ownerUserData,
      },
      ...targetAuthRoles.filter((pR) =>
        authorizationRolesArray.includes(pR.role || "")
      ),
    ];

    if (ownerUserData?.spouse) {
      displayList.splice(1, 0, {
        role: "SPOUSE",
        id: "",
        user_data: ownerUserData.spouse,
        user_data_ud_id: ownerUserData.spouse.ud_id,
      });
    }
  }

  const handleSelect = async (authorization: TargetPeopleRole) => {
    await handleSetAuthorizationContext(
      proClientContext && userData?.ud_id
        ? userData.ud_id
        : ownerUserData?.ud_id || "",
      authorization.user_data_ud_id || null,
      targetAuthRoles,
      null
    );
    router.push(paths.dashboard.index);
  };

  return (
    <>
      <HtmlTooltip
        placement="right-start"
        title={
          <>{`Add a spouse or family member to create their documents.`}</>
        }
      >
        <Stack
          spacing={0}
          sx={{
            backgroundColor: "white",
            p: 1.2,
            m: 1.5,
            mb: 3,
            borderRadius: 2,
            cursor: "pointer",
          }}
        >
          {displayList.map((tenant) => (
            <>
              <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                {...props}
                sx={{
                  backgroundColor:
                    userData?.ud_id === tenant.user_data?.ud_id
                      ? "#fafafa"
                      : "#ffffff",
                  py: 1,
                  mx: 0.5,
                  px: 2,
                  cursor: "pointer",
                  borderRadius: 1,
                  minWidth: "200px",
                }}
                key={tenant.user_data?.ud_id}
                onClick={() => {
                  if (loadingViewingUserData) return;
                  if (clientRole?.is_demo_client) {
                    toast.error(
                      "Upgrade your account to a Pro plan to view other people's plans."
                    );
                    return;
                  }
                  handleSelect && handleSelect(tenant);
                  router.push(paths.dashboard.index);
                }}
              >
                {userData?.ud_id === tenant.user_data?.ud_id ? (
                  <SvgIcon fontSize="small" sx={{ color: "#3e8a28" }}>
                    <CheckCircleSolidIcon />
                  </SvgIcon>
                ) : (
                  <SvgIcon fontSize="small" sx={{ color: "#999" }}>
                    <RadioButtonUncheckedIcon />
                  </SvgIcon>
                )}
                <Box
                  sx={{
                    flexGrow: 1,
                    overflow: "hidden",
                    width: "100%",
                    lineHeight: 0.8,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                      width: "100%",
                      color:
                        userData?.ud_id === tenant.user_data?.ud_id
                          ? "inherit"
                          : "#999",
                    }}
                  >
                    {tenant.user_data?.full_name}
                  </Typography>
                </Box>
                {tenant.role === "SNUG_OWNER" ||
                tenant.is_demo_client ? (
                  <></>
                ) : !tenant.user_data?.has_auth_user ? (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onVerifyUserClick(tenant?.user_data?.ud_id || "");
                    }}
                  >
                    <DotsVertical fontSize="small" />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Stack>
            </>
          ))}
          <Box>
            {loadingViewingUserData || targetAuthRolesLoading ? (
              // centerred circular progress
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress size={20} />
              </Box>
            ) : (
              <>
                {!proClientContext && (
                  <Button
                    size="small"
                    variant="text"
                    color="inherit"
                    onClick={async () => {
                      if (!proClientContext) {
                        if (ownerUserData?.ud_id === userData?.ud_id) {
                          props.onAddPlanClick();
                        } else {
                          await handleSetAuthorizationContext(
                            ownerUserData?.ud_id || "",
                            null,
                            [],
                            null
                          );
                          router.push(paths.dashboard.index);
                        }
                      }
                    }}
                    startIcon={
                      !proClientContext &&
                      ownerUserData?.ud_id === userData?.ud_id ? (
                        <PersonAddOutlinedIcon fontSize="small" />
                      ) : (
                        <ReplyIcon fontSize="small" />
                      )
                    }
                    fullWidth
                  >
                    {ownerUserData?.ud_id === userData?.ud_id
                      ? `Add plan`
                      : `Back to your plan`}
                  </Button>
                )}
              </>
            )}
          </Box>
        </Stack>
      </HtmlTooltip>
    </>
  );
};

TenantSwitch.propTypes = {
  // @ts-ignore
  sx: PropTypes.object,
};
